<template>
  <img src="./assets/background.jpg">
</template>
<style lang="scss">
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: grid;
  place-items: center;
  img {
    width: 100%;
  }
}
</style>
